import { CircularProgress } from '@mui/material';
import React from 'react';

const Spinner = () => {
  return (
    <div className="showbox">
    <div className="logo-loader-wrapper">
      {/* <p/><p/><p/><p/> */}
      <div className='flex items-center justify-center'>
        <img src='/images/airoomdecor-full.webp' width={140} className=''/>
        <CircularProgress size={20} sx={{ color: '#ffc337', marginLeft: '6px' }} />
      </div>
    </div>
  </div>
  );
};

export default Spinner;
