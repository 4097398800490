import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import withBackend from '../../backend/withBackend';
import { setUser } from '../../actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Snackbar } from '@mui/material';
import { getFirestore, collection, addDoc, query, orderBy, limit, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { Rating } from '@mui/material';

const Feedback = ({ isOpen, onClose, onSubmitted, isSubmitted, currentLanguage, engine, userData }) => {
  const feedbackPopupRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [feedbackTextError, setFeedbackTextError] = useState('');
  const [openCoupon, setOpenCoupon] = useState(false);
  const [rating, setRating] = useState(0);
  const [feedbackType, setFeedbackType] = useState('feedback');
  const [feedbackText, setFeedbackText] = useState('');

  const { t } = useTranslation();
  const db = getFirestore();

  // useEffect(() => {
  //   const handleClickOutside = event => {
  //     if (feedbackPopupRef.current && !feedbackPopupRef.current.contains(event.target)) {
  //       onClose();
  //     }
  //   };

  //   document.addEventListener('click', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('click', handleClickOutside);
  //   };
  // }, [onClose]);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (loading) return;

    if (!feedbackText) {
      setFeedbackTextError(t('feedback.emptyFeedback'));
      return;
    }

    if (!feedbackType) {
      setFeedbackTextError(t('feedback.emptyFeedbackType'));
      return;
    }

    setLoading(true);

    const data = {
      uid: userData.uid,
      email: userData.email,
      type: feedbackType,
      content: feedbackText,
      status: 'pending',
      createdAt: new Date().toISOString(),
    }

    engine
    .addFeedback(data)
    .then(result => {
      setLoading(false);
      onSubmitted(true);
      // message.success(t('message.success.subscribed'));
    })
    .catch(err => {
      message.error(err.message);
      console.log(err);
    });
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-75 backdrop-blur-sm flex items-center justify-center z-50 transition-opacity duration-300 ease-in-out"
        style={{ opacity: isOpen ? 1 : 0, pointerEvents: isOpen ? 'auto' : 'none' }}>
        <div ref={feedbackPopupRef}
          className="relative bg-black rounded-lg shadow-lg p-5 max-w-[412px] mx-auto text-white transition-all duration-300 ease-in-out"
          style={{ transform: isOpen ? 'scale(1)' : 'scale(0.9)', opacity: isOpen ? 1 : 0, border: "1px solid #343434" }}>
          <button
            onClick={onClose}
            className="absolute right-2 text-gray-400 hover:text-gray-200 top-1"
          >
            ✕
          </button>
          <div className="flex justify-center mb-4">
            <svg width="50" height="50" viewBox="0 0 0.875 0.875" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" className="bounce-rotate-animation">
              <path fill="#d32f2f" d="M0.729 0.813H0.146a0.042 0.042 0 0 1 -0.042 -0.042V0.354h0.667v0.417c0 0.023 -0.019 0.042 -0.042 0.042" />
              <path fill="#f44336" d="M0.813 0.354H0.063v-0.125C0.063 0.206 0.081 0.188 0.104 0.188h0.667c0.023 0 0.042 0.019 0.042 0.042z" />
              <path fill="#ff8f00" d="M0.396 0.354h0.083V0.813H0.396zm0.125 -0.292 -0.125 0.125h0.083l0.125 -0.125z" />
              <path fill="#ffc107" d="M0.396 0.354h0.083V0.188l-0.125 -0.125H0.271l0.125 0.125z" />
            </svg>
          </div>

          <h2 className="text-center text-2xl font-bold mb-2">
            <div className="mx-auto relative z-[10] text-center mx-auto md:text-center label-giveaway">
              <span>{t('feedback.title')}</span>
            </div>
          </h2>

          <p className="text-center text-gray-400 mb-6">
            {t('feedback.subTitle')}
          </p>
          {isSubmitted ? (
            <div className="flex flex-col items-center justify-center mb-2">
              <hr className="w-full border-t border-gray-700 mb-6" />
              <div className="flex items-center justify-center mb-2">
                <span className="text-white font-semibold">{t('feedback.sent')}</span>
              </div>
              {/* <h2 className="text-center text-2xl font-bold">
                <div className="mx-auto relative z-[10] text-center mx-auto md:text-center label-giveaway">
                  <span>{t('giveAway.emailSentTitle')}</span>&nbsp;🎉
                </div>
              </h2> */}
              <p className="text-gray-400 text-center">
                {t('feedback.sentSub')}
              </p>
            </div>
          ) : (
            <>
              {/* <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t('giveAway.enterYourEmail')}
                className="w-full px-4 py-3 rounded-md border border-gray-700 mb-3 focus:outline-none text-white placeholder-gray-500" style={{ backgroundColor: '#151515' }}
              />
              {emailError && <p className="text-red text-sm mb-4">{emailError}</p>} */}

              {/* <Rating
                name="rating"
                value={rating}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
                className="mb-3"
              /> */}

              <select
                value={feedbackType}
                onChange={(e) => setFeedbackType(e.target.value)}
                className="w-full px-4 py-3 rounded-md border border-gray-700 mb-3 focus:outline-none text-white" style={{ backgroundColor: '#151515' }}
              >
                <option value="feedback">{t('feedback.type.feedback')}</option>
                <option value="bug">{t('feedback.type.bug')}</option>
                <option value="feature">{t('feedback.type.feature')}</option>
                {/* <option value="refund">{t('feedback.type.refund')}</option> */}
              </select>

              <textarea
                value={feedbackText}
                onChange={(e) => setFeedbackText(e.target.value)}
                placeholder={t('feedback.enterYourFeedback')}
                rows={5}
                maxLength={500}
                required
                className="w-full px-4 py-3 rounded-md border border-gray-700 mb-2 focus:outline-none text-white placeholder-gray-500" style={{ backgroundColor: '#151515' }}
              />
              {feedbackTextError && <p className="text-red text-sm mb-4">{feedbackTextError}</p>}
              <button
                onClick={(e) => handleSubmit(e)}
                className="w-full bg-yellow-500 text-black py-4 text-sm rounded-md hover:bg-yellow-400 hover:scale-105 transition duration-300 font-semibold"
              >
                {loading ? t('feedback.submitting') : t('feedback.notifyMe')}
              </button>
            </>
          )}
        </div>
      </div>
      {/* <Snackbar
        open={openCoupon}
        onClose={() => setOpenCoupon(false)}
        autoHideDuration={1500}
        message="Copied DECOR10"
      /> */}
    </>
  );
};

export default Feedback;